import request from '@/utils/request'
// import store from '@/store'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function fetchSubscriptionInvoice (id) {
  return request({
    url: langId + '/subscriptionInvoice/' + id,
    method: 'get'
  })
}

export function fetchSubscriptionMerchant (params) {
  return request({
    url: langId + '/subscription/history',
    method: 'get',
    params: params
  })
}

export function fetchPackageZenwel (params) {
  return request({
    url: langId + '/subscription/package',
    method: 'get',
    params: params
  })
}

export function fetchCurrentPackage (params) {
  return request({
    url: langId + '/subscription/current',
    method: 'get',
    params: params
  })
}

export function fetchPricePlan (params) {
  return request({
    url: langId + '/subscription/price',
    method: 'get',
    params: params
  })
}

export function fetchPayments () {
  return request({
    url: langId + '/paymenttypesubscription',
    method: 'get'
  })
}

export function updatePayment (data) {
  return request({
    url: langId + '/subscriptionInvoice/confirm/' + data.id,
    method: 'put',
    data
  })
}

export function updateSubscription (data) {
  return request({
    url: langId + '/subscriptionInvoice',
    method: 'post',
    data
  })
}

export function getPriceDetail (param) {
  return request({
    url: langId + '/subscription/validation',
    method: 'get',
    params: param
  })
}

export function getCancelData (param) {
  return request({
    url: langId + '/subscription/page/cancel/' + param.id,
    method: 'get',
    params: param
  })
}

export function cancelPayment (data) {
  return request({
    url: langId + '/subscriptionInvoice/cancel/' + data.id,
    method: 'post',
    data
  })
}
