<template>
    <div>
        <v-card class="pb-5">
            <v-toolbar color="elevation-0">
                <v-toolbar-title >
                    <div class="d-flex align-center">
                        <v-btn icon @click="handleBack">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <div class="ml-4 font-weight-bold">{{ lang.t('$vuetify.cancel_transaction') }}</div>
                    </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn icon @click="handleClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <div v-if="!isStaff">
              <div class="mx-6 mt-6">
                  <div class="mt-7" style="width: 100%">
                      <span class="font-14">{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.cancel_option') }}</span>
                      <v-autocomplete v-model="temp.type" :items="cancelOption" item-text="value" outlined dense @change="handleSelectCancel" ></v-autocomplete>
                  </div>
              </div>

              <!-- Cancel Downgrade -->
              <div v-if="!isRefund">
                  <div class="mx-6">
                      <div class="mt-4" style="width: 100%">
                          <span class="font-14">{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.package_to_change') }} </span>
                          <v-autocomplete v-model="temp.subscription_id" :items="dataSubscription" item-text="title" item-value="id" outlined dense @change="handleSelectPackage"></v-autocomplete>
                      </div>
                  </div>
                  <div class="mx-6 mt-6 rounded-lg pa-6" style="box-shadow: 0px 0px 24px #00000014;">
                      <div class="d-flex">
                          <div class="full-width">
                              <div class="font-16">{{ dataCancel.subscriptionname }} {{ lang.t('$vuetify.package_epired_on') }}</div>
                              <div class="font-16">{{ dataCancel.expiredon }}</div>
                              <div class="font-14 mt-4" style="color: #00D79E">Detail</div>
                          </div>
                          <div class="full-width d-flex align-center">
                              <span class="mr-4">Custom {{ lang.t('$vuetify.expiry_date') }}</span>
                              <v-switch v-model="customDate"></v-switch>
                          </div>
                      </div>
                      <hr class="mt-3" style="border: 1px dashed #CDD2DA">
                      <div v-if="customDate" class="mt-6 d-flex align-center" >
                          <div class="mr-2" style="max-width: 192px">
                              <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="temp.end_date"
                                  transition="scale-transition"
                                  offset-y
                              >
                                  <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="dateComputed"
                                      :label="lang.t('$vuetify.select') + lang.t('$vuetify.expiry_date')"
                                      append-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                  ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="temp.end_date" :max="temp.max_date" no-title scrollable>
                                  <v-spacer></v-spacer>
                                  <v-btn text color="primary" @click="menu = false">{{ lang.t('$vuetify.cancel') }}</v-btn>
                                  <v-btn text color="primary" @click="changeDate">OK</v-btn>
                                  </v-date-picker>
                              </v-menu>
                          </div>
                          <div>
                              <v-chip v-for="item in dateOption" :key="item.id" :disabled="item.disabled" class="mr-2" small outlined @click="handleDateOption(item.id)">{{ item.label }}</v-chip>
                          </div>
                      </div>
                  </div>
              </div>

              <!-- Cancel Refund -->
              <div v-else>
                  <div class="mx-6">
                      <div class="mt-4 radius-8 d-flex align-center pl-3" style="width: 100%; height: 56px; background: #DDFBF3">
                          <v-icon small color="#00D79E mr-2" style="font-size: 20px" >mdi-information-outline</v-icon>
                          <div class="font-12" style="color: #00D79E">{{ lang.t('$vuetify.automatic_downgrade_note') }}</div>
                      </div>
                  </div>
                  <div class="mx-6 mt-6 rounded-lg pa-6" style="box-shadow: 0px 0px 24px #00000014;">
                      <div class="d-flex">
                          <div class="full-width">
                              <div class="font-16">{{ lang.t('$vuetify.merchant_will_get_refund') }}</div>
                              <div class="font-16 font-weight-bold">Rp. {{ dataCancel.ftotal_refund }}</div>
                              <div class="font-14 mt-4" style="color: #00D79E">Detail</div>
                          </div>
                          <div class="full-width d-flex align-center justify-end">
                              <span class="mr-4">Custom Refund</span>
                              <v-switch v-model="customRefund"></v-switch>
                          </div>
                      </div>
                      <hr class="mt-3" style="border: 1px dashed #CDD2DA">
                      <div v-if="customRefund" class="mt-6 d-flex align-center" >
                          <v-text-field v-model="temp.price" dense prefix="Rp." height="40px" outlined style="min-height: unset !important"></v-text-field>
                      </div>
                  </div>
                  <div class="mx-6 mt-6">
                      <div class="mt-7" style="width: 100%">
                          <span class="font-14">Refund {{ lang.t('$vuetify.merchant_will_get_refund') }}</span>
                          <v-autocomplete v-model="temp.payment_method" :items="refundOption" item-text="name" item-value="label" outlined dense @change="handleRefundOption" ></v-autocomplete>
                      </div>
                  </div>
                  <div v-if="isTransfer" class="mx-6 d-flex">
                      <div class="full-width pr-2">
                          <span class="font-14">Bank Name</span>
                          <v-text-field v-model="temp.bank" outlined dense height="40px" style="min-height: unset !important"></v-text-field>
                      </div>
                      <div class="full-width pl-2">
                          <span class="font-14">Account Number</span>
                          <v-text-field v-model="temp.account_no" outlined dense height="40px" style="min-height: unset !important"></v-text-field>
                      </div>
                  </div>
              </div>
            </div>

            <!-- select staff -->
            <div class="mx-6" v-if="isStaff">
              <div class="d-flex align-center mb-2">
                <v-menu offset-y class="mb-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="text-capitalize" depressed color="#F5F5F5" small v-bind="attrs" v-on="on">
                      Sort by: {{ params.sort_by_staff }}
                      <v-icon right dark>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in dropdown_staff"
                      :key="index"
                      @click="handleStaff(item)">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div class="full-width text-right">
                  {{ totalStaff }} of {{ dataCancel.max_staff }} staff
                </div>
              </div>
              <div class="rounded-lg px-2 py-2" style="border: 1px solid #E0E0E0; max-height: 380px; overflow-y: auto;">
                <div class="mb-2 d-flex align-center pa-2" v-for="staff in dataCancel.staff" :key="staff.id" style="height: 64px">
                  <div class="mr-2">
                    <v-avatar size="40">
                      <v-img :src="staff.photo"></v-img>
                    </v-avatar>
                  </div>
                  <div class="full-width">
                    <span class="font-16 font-bold">{{ staff.name}}</span>
                    <div class="font-14">{{ staff.role_name }}</div>
                  </div>
                  <div class="text-right">
                    <v-checkbox v-model="staff.checked" :disabled="staff.disabled" @change="checkedStaff"/>
                  </div>
                </div>
              </div>
            </div>
            <div style="box-shadow: 0px -5px 5px #F5F5F5; width: 100%">
              <div v-if="isStaff" class="pt-4 px-4 mt-4 full-width text-right">
                <div >
                    <v-btn v-if="dataCancel.is_unlock_staff === 1" color="#00D79E" style="color: #FFFFFF" @click="handleApply">{{ lang.t('$vuetify.apply') }}</v-btn>
                </div>
              </div>
              <div v-else class="d-flex pt-4 px-4 mt-4 full-width">
                <div class="ml-2 caption" style="width: 80%">
                    <div class="font-14">{{ temp.type === 'Downgrade' ? 'Downgrade to' : 'Total Refund'}}</div>
                    <div class="font-20 font-weight-bold">{{ temp.type === 'Downgrade' ? dataCancel.planperiod : 'Rp. ' + dataCancel.ftotal_refund }}</div>
                </div>
                <div>
                  <v-btn v-if="dataCancel.is_unlock_staff === 1 && temp.type === 'Downgrade'" color="#00D79E" style="color: #FFFFFF" @click="handleNext">{{ lang.t('$vuetify.next') }}</v-btn>
                  <v-btn v-else color="#00D79E" style="color: #FFFFFF" @click="handleApply">{{ lang.t('$vuetify.apply') }}</v-btn>
                </div>
              </div>
            </div>
        </v-card>
    </div>
</template>
<script>
import { fetchSubscriptionList } from '@/api/zenwelbiz'
import { getCancelData, cancelPayment } from '@/api/zenwel-biz/merchant'
var moment = require('moment')

export default {
  name: 'CancelPackage',
  props: ['billingData'],

  data () {
    return {
      dataSubscription: [],
      cancelOption: [{ value: 'Refund', disabled: false }, { value: 'Downgrade', disabled: false }],
      temp: {
        subscription_id: null,
        type: 'Downgrade',
        payment_method: 'transfer',
        end_date: '',
        max_date: ''
      },
      dataCancel: {},
      isRefund: false,
      isTransfer: true,
      customDate: false,
      customRefund: false,
      date: moment(new Date()).format('YYYY-MM-DD'),
      refundOption: [{ name: 'Bank Transfer', label: 'transfer' }, { name: 'Cash', label: 'cash' }],
      dateOption: [ { id: 0, label: '1 month', disabled: false }, { id: 1, label: '3 months', disabled: false }, { id: 2, label: '6 months', disabled: false }, { id: 3, label: '1 year', disabled: false } ],
      menu: false,
      isStaff: false,
      dropdown_staff: [ { text: 'Position', id: 1 }, { text: 'Date', id: 2 } ],
      totalStaff: 1,
      params: {
        sort_by_staff: 'date'
      }
    }
  },

  computed: {
    dateComputed () {
      return moment(this.temp.end_date).format('DD MMM YYYY')
    },

    defaultSubcription () {
      let subsid = 0
      if (this.billingData.current_subcription_status === 'Enterprise') {
        subsid = 3
      }
      if (this.billingData.current_subcription_status === 'Pro') {
        subsid = 2
      }
      if (this.billingData.current_subcription_status === 'Basic') {
        subsid = 1
      }
      return subsid
    },

    currentSubId () {
      let id = 0
      if (this.billingData.current_subcription_status === 'Enterprise') {
        id = 4
      }
      if (this.billingData.current_subcription_status === 'Pro') {
        id = 3
      }
      if (this.billingData.current_subcription_status === 'Basic') {
        id = 2
      }
      if (this.billingData.current_subcription_status === 'Free') {
        id = 1
      }
      return id
    },

    lang () {
      return this.$vuetify.lang
    }
  },

  watch: {
    dataCancel (data) {
      console.log('da', data)
    }
  },

  mounted () {
    this.getSubscriptionList()
    this.getData(true)
    console.log('moment', moment('2020-10-02'))
  },

  methods: {
    getSubscriptionList () {
    // this.listLoading = true
      fetchSubscriptionList().then((response) => {
        this.dataSubscription = response.data.data
        this.dataSubscription.map((i, idx) => {
          i.disabled = false
          if (this.currentSubId === 4) {
            if (idx === 3 || idx === 0) {
              console.log('idx', idx)
              i.disabled = true
            }
          }
          if (this.currentSubId === 3) {
            if (idx >= 3 || idx === 2 || idx === 0) {
              i.disabled = true
            }
          }
          if (this.currentSubId === 2) {
            this.isRefund = true
            this.temp.type = 'Refund'
            this.cancelOption[1].disabled = true
          }
        })
        this.temp.subscription_id = this.defaultSubcription
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getData (start) {
      let param = {}
      if (start) {
        if (this.defaultSubcription === 1) {
          param = {
            id: this.billingData.id,
            type: 'refund',
            payment_method: 'transfer'
          }
        } else {
          param = {
            id: this.billingData.id,
            type: this.temp.type.toLowerCase(),
            subscription_id: this.defaultSubcription
          }
        }
      } else {
        if (this.temp.type === 'Downgrade') {
          param = {
            id: this.billingData.id,
            type: this.temp.type.toLowerCase(),
            subscription_id: this.temp.subscription_id
          }
          if (this.temp.end_date) {
            param.end_date = this.temp.end_date
          }
        }
        if (this.temp.type === 'Refund') {
          param = {
            id: this.billingData.id,
            type: this.temp.type.toLowerCase(),
            payment_method: this.temp.payment_method
          }
          if (this.customRefund) {
            param.price = this.temp.price
          }
        }
      }
      param.sort_by_staff = this.params.sort_by_staff

      getCancelData(param).then((response) => {
        this.dataCancel = response.data.data
        this.dataCancel.staff.map((i, idx) => {
          if (i.role_id === 'O') {
            i.checked = true
            i.disabled = true
          } else if (idx < this.dataCancel.max_staff) {
            i.checked = true
            i.disabled = false
          } else {
            i.checked = false
            i.disabled = false
          }
        })
        this.checkedStaff()
        this.temp.end_date = response.data.data.expired_at
        if (start) {
          this.temp.max_date = response.data.data.expired_at
          this.checkDateOption()
        }
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    handleNext () {
      this.isStaff = true
    },

    handleApply () {
      let body = {}
      if (this.temp.type === 'Downgrade') {
        body = {
          id: this.billingData.id,
          type: this.temp.type.toLowerCase(),
          subscription_id: this.temp.subscription_id
        }
        if (this.temp.end_date) {
          body.end_date = this.temp.end_date
        }

        if (this.dataCancel.is_unlock_staff === 1) {
          let checked = []
          this.dataCancel.staff.map(i => {
            if (i.checked) {
              checked.push({ staff_id: i.id })
            }
          })
          body.unlockstaff = checked
        }
      }
      if (this.temp.type === 'Refund') {
        body = {
          id: this.billingData.id,
          type: this.temp.type.toLowerCase(),
          payment_method: this.temp.payment_method
        }
        if (this.customRefund) {
          body.price = this.temp.price
        } else {
          body.price = this.dataCancel.total_refund
        }
        if (this.temp.payment_method === 'transfer') {
          body.bank = this.temp.bank
          body.account_no = this.temp.account_no
        }
      }

      console.log('bod', body)

      cancelPayment(body).then((response) => {
        console.log('res', response.data.data)
        this.$emit('update')
        // this.dataCancel = response.data.data
        // this.temp.end_date = response.data.data.expired_at
        // if (start) {
        //   this.temp.max_date = response.data.data.expired_at
        //   this.checkDateOption()
        // }
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    checkDateOption () {
      this.dateOption.map(i => {
        if (i.id === 0) {
          let date = moment().add(1, 'month').format('YYYY-MM-DD')
          if (moment(date).isAfter(this.temp.max_date)) {
            i.disabled = true
          }
        }
        if (i.id === 1) {
          let date = moment().add(3, 'month').format('YYYY-MM-DD')
          if (moment(date).isAfter(this.temp.max_date)) {
            i.disabled = true
          }
        }
        if (i.id === 2) {
          let date = moment().add(6, 'month').format('YYYY-MM-DD')
          if (moment(date).isAfter(this.temp.max_date)) {
            i.disabled = true
          }
        }
        if (i.id === 3) {
          let date = moment().add(1, 'year').format('YYYY-MM-DD')
          if (moment(date).isAfter(this.temp.max_date)) {
            i.disabled = true
          }
        }
      })
    },

    handleDateOption (id) {
      if (id === 0) {
        this.temp.end_date = moment().add(1, 'month').format('YYYY-MM-DD')
      }
      if (id === 1) {
        this.temp.end_date = moment().add(3, 'month').format('YYYY-MM-DD')
      }
      if (id === 2) {
        this.temp.end_date = moment().add(6, 'month').format('YYYY-MM-DD')
      }
      if (id === 3) {
        this.temp.end_date = moment().add(1, 'year').format('YYYY-MM-DD')
      }
    },

    handleSelectCancel (data) {
      if (data === 'Refund') {
        this.isRefund = true
      } else {
        this.isRefund = false
      }
      this.getData()
    },

    handleRefundOption (data) {
      if (data === 'transfer') {
        this.isTransfer = true
      } else {
        this.isTransfer = false
      }
      this.getData()
    },

    handleBack () {
      this.$emit('back')
    },

    handleClose () {
      this.$emit('close')
    },

    handleSelectPackage (data) {
      this.getData()
    },

    changeDate (data) {
      console.log('data', data)
      this.$refs.menu.save(this.temp.end_date)
      this.menu = false
      this.getData()
    },

    handleStaff (data) {
      this.params.sort_by_staff = data.text.toLowerCase()
      this.getData()
    },

    checkedStaff () {
      let count = []
      this.dataCancel.staff.map(i => {
        if (i.checked) {
          count.push(i.id)
        }
      })
      this.totalStaff = count.length
    }
  }
}
</script>
