<template>
  <div>
    <v-container class="grey lighten-5 fill-height" style="width: 100%; max-width: none">
      <div style="background: #ffffff; box-shadow: 0px 0px 12px #0000000A; border-radius: 16px; width: 100%;">
        <v-tabs
            v-model="tab"
            align-with-title
            color="#00D79E"
            background-color="transparent"
            style="width: 100%;">
            <v-tabs-slider color="#00D79E"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item.id" class="text-capitalize" style="font-size: 18px">
            {{ item.name }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <profile ></profile>
          </v-tab-item>
          <v-tab-item>
            <subscription ></subscription>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import Profile from './profile'
import Subscription from './subscription'
export default {
  name: 'BusinessIndex',
  components: {
    Profile,
    Subscription
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  data () {
    return {
      tab: null,
      items: [
        {
          id: 0,
          name: this.$vuetify.lang.t('$vuetify.bussiness')
        },
        {
          id: 1,
          name: this.$vuetify.lang.t('$vuetify.subscription')
        },
        {
          id: 2,
          name: this.$vuetify.lang.t('$vuetify.marketplace')
        },
        {
          id: 3,
          name: this.$vuetify.lang.t('$vuetify.biz_deal')
        }
      ]
    }
  }

}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
