<template>
  <div>
    <div>
      <v-container class="grey lighten-5" style="max-width: 1280px !important">
        <v-row
          class="mb-6"
          no-gutters
        >
          <v-col :md="8" class="pr-3">
            <v-card :shaped="true" :raised="true" :elevation="5" class="rounded-lg pa-5" style="height: 100%; max-height: 216px" >
              <span class="font-weight-medium" style="font-size: 22px;">{{ dataMerchant.name }}</span>
              <div class="d-flex flex-row mb-6">
                <div style="font-size: 14px">{{ lang.t('$vuetify.business_type') }} : {{ dataMerchant.business_type_name }}</div>
              </div>
              <div class="d-flex align-center" style="width: 100%">
                <v-card :shaped="true" :raised="true" :elevation="5" class="d-flex align-center rounded-xl mb-3 text-center" style="width: 236px; height: 72px; background: transparent linear-gradient(180deg, #46E8BD 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;" >
                  <div style="width: 100%">
                    <div class="font-weight-medium" style="color: #FFFFFF; font-size: 24px">{{ dataMerchant.subscription_status }}</div>
                  </div>
                </v-card>
                <div class="px-3" style="width: 100%;">
                  <div class="d-flex align-center">
                    <span style="color: #767676; font-size: 14px; width: 100%">{{ dataMerchant.timeleft }}</span>
                    <div class="text-right" style="color: #767676; font-size: 18px; width: 100%;">1 Year (expired at {{ dataMerchant.fend_date_subscription}})</div>
                  </div>
                  <v-progress-linear :value="dataMerchant.pecentagetimeleftsubscription" height="16px" rounded color="#00D79E" background-color="#2882F5"></v-progress-linear>
                  <div class="d-flex align-center">
                    <span style="font-size: 14px; color: #F5295A; width: 100%">Time left: {{ dataMerchant.dayexpiredused }}</span>
                    <span class="text-right" style="font-size: 14px; color: #2882F5; width: 100%">{{ lang.t('$vuetify.your_package_by_extend') }}</span>
                  </div>
                </div>
              </div>
            </v-card>
            <v-row>
                <v-col :md="3">
                    <v-card ref="1" :raised="true" :elevation="5" class="rounded-lg pa-2 zoom" style="height: 328px; background: #0092FF" @mouseover="setColor(0)" @mouseleave="reColor(0)">
                    <div class="text-center mt-16 pt-5">
                        <v-icon x-large class="mt-2 mb2" color="#0092FF" stroke="#FFFFFF">$vuetify.icons.icon_staff</v-icon>
                        <div class="mt-3" style="color: #FFFFFF">
                            <div>{{ dataMerchant.total_staff }}</div>
                            <span>Staff</span>
                        </div>
                    </div>
                    </v-card>
                </v-col>
                <v-col :md="3">
                    <v-card ref="1" :raised="true" :elevation="5" class="rounded-lg pa-2 zoom" style="height: 328px; background: #00C7C5" @mouseover="setColor(0)" @mouseleave="reColor(0)">
                    <div class="text-center mt-16 pt-5">
                        <v-icon x-large class="mt-2 mb2" color="#00C7C5" stroke="#FFFFFF">$vuetify.icons.icon_service</v-icon>
                        <div class="mt-3" style="color: #FFFFFF">
                            <div>{{ dataMerchant.total_service }}</div>
                            <span>Service</span>
                        </div>
                    </div>
                    </v-card>
                </v-col>
                <v-col :md="3">
                    <v-card ref="1" :raised="true" :elevation="5" class="rounded-lg pa-2 zoom" style="height: 328px; background: #FF8B77" @mouseover="setColor(0)" @mouseleave="reColor(0)">
                    <div class="text-center mt-16 pt-5">
                        <v-icon x-large class="mt-2 mb2">$vuetify.icons.class</v-icon>
                        <div class="mt-3" style="color: #FFFFFF">
                            <div>{{ dataMerchant.total_class }}</div>
                            <span>Classes</span>
                        </div>
                    </div>
                    </v-card>
                </v-col>
                <v-col :md="3">
                    <v-card ref="1" :raised="true" :elevation="5" class="rounded-lg pa-2 zoom" style="height: 328px; background: #00D6EF" @mouseover="setColor(0)" @mouseleave="reColor(0)">
                    <div class="text-center mt-16 pt-5">
                        <v-icon x-large class="mt-2 mb2" color="#00D6EF" stroke="#FFFFFF">$vuetify.icons.icon_product</v-icon>
                        <div class="mt-3" style="color: #FFFFFF">
                            <div>{{ dataMerchant.total_product }}</div>
                            <span>Product</span>
                        </div>
                    </div>
                    </v-card>
                </v-col>
            </v-row>
          </v-col>
          <v-col :md="4" class="pl-3">
            <v-card :shaped="true" :raised="true" :elevation="5" class="rounded-lg">
              <v-img src="@/assets/back_purple.svg" class="pl-5 pt-5" style="height: 216px">
                <div class="d-flex flex-row">
                  <v-row>
                    <v-col :md="4">
                      <div class="text-body-2 mt-3">{{ lang.t('$vuetify.joined_date') }}</div>
                      <div class="text-body-2 mt-3">{{ lang.t('$vuetify.company_city') }}</div>
                      <div class="text-body-2 mt-3">{{ lang.t('$vuetify.owner_phone') }}</div>
                      <div class="text-body-2 mt-3">{{ lang.t('$vuetify.total') }} {{ lang.t('$vuetify.location') }}</div>
                    </v-col>
                    <v-col :md="6">
                      <div class="text-body-2 font-weight-bold mt-3">{{ dataMerchant.fjoined_date }}</div>
                      <div v-if="dataMerchant.city_name !== null" class="text-body-2 font-weight-bold mt-3">{{ dataMerchant.city_name }}</div>
                      <div v-else class="text-body-2 font-weight-bold mt-3">-</div>
                      <div class="text-body-2 font-weight-bold mt-3">{{ dataMerchant.owner_phone }}</div>
                      <div class="text-body-2 font-weight-bold mt-3">{{ dataMerchant.total_location }}</div>
                    </v-col>
                  </v-row>
                </div>
              </v-img>
            </v-card>
            <v-card :shaped="true" :raised="true" :elevation="5" class="rounded-xl mt-3 text-center" style="background: #00D6EF; height: 328px;" >
              <v-img src="@/assets/back_pink.svg" class="pt-10" style="height: 272px">
                <span style="color: #FFFFFF">{{ lang.t('$vuetify.marketplace_status') }}</span>
                <div v-if="dataMerchant.publish_to_marketplace === 1" class="text-h3 font-weight-medium" style="color: #FFFFFF">{{ lang.t('$vuetify.published') }}</div>
                <div v-else class="text-h3 font-weight-medium" style="color: #FFFFFF">{{ lang.t('$vuetify.unpublished') }}</div>
                <div class="px-5 mb-3" style="position: absolute; bottom: 0px; width: 100%">
                    <v-btn depressed style="width: 100%; color: #00D6EF"><span class="text-capitalize">{{ lang.t('$vuetify.manage') }} {{ lang.t('$vuetify.marketplace') }}</span></v-btn>
                </div>
              </v-img>
            </v-card>
          </v-col>
          <!-- <v-col :md="4" class="pl-3">
            <v-card :shaped="true" :raised="true" :elevation="5" class="rounded-xl mb-3 text-center" style="background: #00C7C5;" >
              <v-img src="@/assets/back_pink.svg" class="pt-10" style="height: 252px">
                <span style="color: #FFFFFF">Subscription Package</span>
                <div class="text-h3 font-weight-medium" style="color: #FFFFFF">{{ dataMerchant.subscription_status }}</div>
                <div class="px-5 mb-3" style="position: absolute; bottom: 0px; width: 100%">
                    <v-btn depressed style="width: 100%; color: #00C7C5"><span class="text-capitalize">Manage Subscription</span></v-btn>
                </div>
              </v-img>
            </v-card>
            <v-card :shaped="true" :raised="true" :elevation="5" class="rounded-xl mb-3 text-center" style="background: #00D6EF;" >
              <v-img src="@/assets/back_pink.svg" class="pt-10" style="height: 272px">
                <span style="color: #FFFFFF">Marketplace Status</span>
                <div v-if="dataMerchant.publish_to_marketplace === 1" class="text-h3 font-weight-medium" style="color: #FFFFFF">Published</div>
                <div v-else class="text-h3 font-weight-medium" style="color: #FFFFFF">Unpublished</div>
                <div class="px-5 mb-3" style="position: absolute; bottom: 0px; width: 100%">
                    <v-btn depressed style="width: 100%; color: #00D6EF"><span class="text-capitalize">Manage Marketplace</span></v-btn>
                </div>
              </v-img>
            </v-card>
          </v-col> -->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'
import { detailBussiness } from '@/api/zenwelbiz'
import {
  countRevenue,
  countBussiness,
  countMarketplace,
  countAppointment,
  platformDemograph,
  treatementTypeDemograph,
  subcriptionOverview,
  appointmentOverview } from '@/api/dashboard'

export default {
  name: 'Profile',
  data () {
    return {
      alignments: [
        'center'
      ],
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Joined Date',
          align: 'right',
          value: 'fjoined_date',
          width: 150
        },
        {
          text: 'Phone',
          align: 'center',
          value: 'owner_phone'
        },
        {
          text: 'City',
          align: 'left',
          value: 'total_city'
        },
        {
          text: 'Location',
          align: 'left',
          value: 'total_location'
        },
        {
          text: 'Staff',
          align: 'left',
          value: 'total_staff'
        },
        {
          text: 'Service',
          align: 'left',
          value: 'total_service'
        },
        {
          text: 'Class',
          align: 'left',
          value: 'total_class'
        },
        {
          text: 'Product',
          align: 'left',
          value: 'total_product'
        },
        {
          text: 'Action',
          align: 'center',
          value: 'action',
          width: 150
        }
      ],
      desserts: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam'
        }
      ],
      headers2: [
        {
          text: 'Company',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Expired Date',
          align: 'center',
          value: 'date'
        },
        {
          text: 'Time Left',
          align: 'right',
          value: 'time_left'
        }
      ],
      desserts2: [
        {
          name: 'Djemari Salon and Spa',
          city: 'Yogyakarta',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialist',
          city: 'Yogyakarta',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Florent Women Salon Specialis',
          city: 'Yogyakarta',
          date: '21 Agustus 2019',
          time_left: '2 days'
        },
        {
          name: 'Kakiku Reflexologi',
          city: 'Batam',
          date: '21 Agustus 2019',
          time_left: '2 days'
        }
      ],
      lineSettings: {
        area: true,
        smooth: 0.6,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        show: true,
        boundaryGap: false,
        splitLine: {
          show: false
        }
      },
      yAxis: {
        show: false,
        boundaryGap: false,
        splitLine: {
          show: false
        }
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsLine: ['#00D79E', '#1DA1F3'],
      dataLine: {
        columns: ['date', 'sales'],
        rows: [{ 'date': '10 Apr', 'sales': 20 },
          { 'date': '11 May', 'sales': 45 },
          { 'date': '12 Jun', 'sales': 30 },
          { 'date': '13 Jul', 'sales': 40 },
          { 'date': '14 Aug', 'sales': 60 },
          { 'date': '15 Sept', 'sales': 57 }
        ]
      },
      histogramSettings: {
        metrics: ['confirmed', 'cancelled'],
        stack: { 'sales': ['confirmed', 'cancelled'] }
      },
      colorsHisto: ['#00D79E', '#F5295A'],
      dataUpcomingAppointment: {
        columns: ['date', 'confirmed', 'cancelled'],
        rows: [{ 'date': 'Mon 10', 'confirmed': 2, 'cancelled': 2 },
          { 'date': 'Tue 11', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Wed 12', 'confirmed': 4, 'cancelled': 0 },
          { 'date': 'Thr 13', 'confirmed': 2, 'cancelled': 0 },
          { 'date': 'Fri 14', 'confirmed': 6, 'cancelled': 2 },
          { 'date': 'Sat 15', 'confirmed': 7, 'cancelled': 0 },
          { 'date': 'Sun 16', 'confirmed': 3, 'cancelled': 2 }
        ]
      },
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      dropdown_font: ['All City', 'Yogyakarta', 'Batam', 'Jakarta'],
      selectCity: '',
      // from api ///////////////////////////////////////////////////////////
      // ///////////////////////////////////////////////////////////////////
      newCompany: [],
      expiredCompany: [],
      ringSettings: {
        offsetY: 110,
        label: {
          normal: {
            show: true,
            position: 'inside'
          }
        }
      },
      legend1: {
        orient: 'vertical',
        right: -10,
        top: 'center',
        bottom: 20
      },
      dataChartPlatform: {
        columns: ['platform', 'sales'],
        rows: [{ 'platform': '70%', 'sales': 70, 'name': 'Bis' },
          { 'platform': '20%', 'sales': 20, 'name': 'Bis' },
          { 'platform': '10%', 'sales': 10, 'name': 'Bis' }
        ]
      },
      dataChartTreatment: {
        columns: ['treatment', 'sales'],
        rows: [{ 'treatment': '50%', 'name': 'Hair', 'color': '#83D9FF', 'sales': 50 },
          { 'treatment': '13%', 'name': 'Nails', 'color': '#7CFDBE', 'sales': 13 },
          { 'treatment': '11%', 'name': 'Massage', 'color': '#FF94CD', 'sales': 11 },
          { 'treatment': '8%', 'name': 'Face', 'color': '#C7A8FF', 'sales': 8 },
          { 'treatment': '6%', 'name': 'Hair Removal', 'color': '#B6C2FF', 'sales': 6 },
          { 'treatment': '5%', 'name': 'Body', 'color': '#FFB677', 'sales': 5 },
          { 'treatment': '3%', 'name': 'Phsical Therapy', 'color': '#FFD740', 'sales': 3 },
          { 'treatment': '2%', 'name': 'Conselling & Holistic', 'color': '#8CFFFF', 'sales': 2 },
          { 'treatment': '2%', 'name': 'Medical & Dental', 'color': '#FF845D', 'sales': 2 }
        ]
      },
      dataChartSubscription: {
        columns: ['treatment', 'sales'],
        rows: [{ 'treatment': '30%', 'name': 'Free', 'color': 'transparent linear-gradient(180deg, #E0E0E0 0%, #EEEEEE 100%) 0% 0% no-repeat padding-box;', 'sales': 30 },
          { 'treatment': '10%', 'name': 'Basic', 'color': 'transparent linear-gradient(180deg, #46E8BD 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;', 'sales': 10 },
          { 'treatment': '45%', 'name': 'Pro', 'color': 'transparent linear-gradient(0deg, #2882F5 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;', 'sales': 45 },
          { 'treatment': '15%', 'name': 'Enterprise', 'color': 'transparent linear-gradient(180deg, #7E11A1 0%, #BC4DAA 100%) 0% 0% no-repeat padding-box;', 'sales': 15 }
        ]
      },
      colorsRing: ['#00D79E', '#00B082', '#004A37'],
      colorsTreatment: ['#83D9FF', '#7CFDBE', '#FF94CD', '#C7A8FF', '#B6C2FF', '#FFB677', '#FFD740', '#8CFFFF', '#FF845D'],
      colorsSubscription: ['#E0E0E0', '#46E8BD', '#2882F5', '#7E11A1'],
      dataCountRevenue: {
        invoice: {},
        bussiness: {},
        marketplace: {},
        appointment: {}
      },
      dataPlatform: {
        columns: ['fpercent', 'percent'],
        rows: []
      },
      dataTreatmentDemograph: {
        columns: ['fpercent', 'percent'],
        rows: []
      },
      dataSubscription: {
        total_business: 0,
        frevenue: '0',
        revenue: 0,
        pieData: {
          columns: ['fpercent', 'percent'],
          rows: []
        },
        chartData: {
          columns: ['flabel', 'value'],
          rows: []
        }
      },
      dataWidget: {
        total_appointment: 0,
        frevenue: '0',
        revenue: 0,
        pieData: {
          columns: ['fpercent', 'percent'],
          rows: []
        },
        chartData: {
          columns: ['flabel', 'value'],
          rows: []
        }
      },
      dataMarketplace: {
        total_appointment: 0,
        frevenue: '0',
        revenue: 0,
        pieData: {
          columns: ['fpercent', 'percent'],
          rows: []
        },
        chartData: {
          columns: ['flabel', 'value'],
          rows: []
        }
      },
      filterBtn: [
        {
          id: 1,
          value: '1 Week',
          string: '1week',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 2,
          value: '1 Month',
          string: '1month',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 3,
          value: '1 Year',
          string: '1year',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 4,
          value: '3 Year',
          string: '3year',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 5,
          value: '5 Year',
          string: '5year',
          color: '#E0E0E0',
          colorTxt: '#767676'
        }
      ],
      date_start: moment().subtract(6, 'd').format('YYYY-MM-DD'),
      date_end: moment().format('YYYY-MM-DD'),
      styleMenu: [
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        },
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        },
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        },
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        }
      ],
      dataMerchant: [],
      search: ''
    }
  },

  mounted () {
    console.log('tag', this.$vuetify.icons)
    console.log('tag', this.$store)
    this.getAllActivity()
  },

  computed: {
    computedDateFormattedMomentjs () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date[0] ? moment(this.date[0]).format('D') : ''
      endDate = this.date[1] ? moment(this.date[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    computedDateFormattedMomentjs1 () {
      var startDate = ''
      var endDate = ''
      var ret = ''
      startDate = this.date2[0] ? moment(this.date2[0]).format('D') : ''
      endDate = this.date2[1] ? moment(this.date2[1]).format('D MMMM YYYY') : ''
      ret = startDate + ' - ' + endDate
      return ret
    },
    activeFilter () {
      if (this.model == null) return undefined
      return this.filters[this.model].fn
    },
    columnsAdmin () {
      let columns = []
      let mid = Math.ceil(this.dataTreatmentDemograph.rows.length / 2)
      for (let col = 0; col < 2; col++) {
        columns.push(this.dataTreatmentDemograph.rows.slice(col * mid, col * mid + mid))
      }
      return columns
    },
    lang () {
      return this.$vuetify.lang
    }
  },

  methods: {
    getAllActivity (page) {
      this.listLoading = true
      detailBussiness(this.$route.params.id).then((response) => {
        this.dataMerchant = response.data.data
        this.total_page = response.data.meta.last_page
        this.page = response.data.meta.current_page
        this.total_activity = response.data.meta.total
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },
    fetchCountRevenue () {
      // this.listLoading = true
      countRevenue().then((response) => {
        this.dataCountRevenue.invoice = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchCountBusiness () {
      // this.listLoading = true
      countBussiness().then((response) => {
        this.dataCountRevenue.bussiness = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchCountMarketplace () {
      // this.listLoading = true
      countMarketplace().then((response) => {
        this.dataCountRevenue.marketplace = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchCountAppointment () {
      // this.listLoading = true
      countAppointment().then((response) => {
        this.dataCountRevenue.appointment = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchPlatformDemograph (filterDate) {
      // this.listLoading = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end
      }

      if (typeof filterDate !== 'undefined') {
        if (filterDate.id !== 2) {
          param.date_start = filterDate.date_start
          param.date_end = filterDate.date_end
        } else {
          param = {}
        }
      }
      platformDemograph(param).then((response) => {
        this.dataPlatform.rows = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchTreatmentDemograph (filterDate) {
      // this.listLoading = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end
      }

      if (typeof filterDate !== 'undefined') {
        if (filterDate.id !== 2) {
          param.date_start = filterDate.date_start
          param.date_end = filterDate.date_end
        } else {
          param = {}
        }
      }
      treatementTypeDemograph(param).then((response) => {
        this.dataTreatmentDemograph.rows = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchSubcriptionOverview (filterDate) {
      // this.listLoading = true
      let param = {}
      if (typeof filterDate !== 'undefined') {
        param.date_start = filterDate.date_start
        param.date_end = filterDate.date_end
      } else {
        param.date_start = this.date_start
        param.date_end = this.date_end
      }
      subcriptionOverview(param).then((response) => {
        this.dataSubscription.total_business = response.data.data.total_business
        this.dataSubscription.ftotalrevenue = response.data.data.graph.ftotalrevenue
        this.dataSubscription.totalrevenue = response.data.data.graph.totalrevenue
        this.dataSubscription.pieData.rows = response.data.data.subscription
        this.dataSubscription.date_start = moment(response.data.data.graph.date_start).format('DD MMM YYYY')
        this.dataSubscription.date_end = moment(response.data.data.graph.date_end).format('DD MMM YYYY')
        this.dataSubscription.pieData.rows.map(i => {
          if (i.name === 'Free') {
            i.color = 'transparent linear-gradient(180deg, #E0E0E0 0%, #EEEEEE 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.name === 'Basic') {
            i.color = 'transparent linear-gradient(180deg, #46E8BD 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.name === 'Pro') {
            i.color = 'transparent linear-gradient(0deg, #2882F5 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.name === 'Enterprise') {
            i.color = 'transparent linear-gradient(180deg, #7E11A1 0%, #BC4DAA 100%) 0% 0% no-repeat padding-box;'
          }
        })
        this.dataSubscription.chartData.rows = response.data.data.graph.datagraph
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchWidgetOverview (filterDate) {
      // this.listLoading = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end,
        platform: 'O'
      }

      if (typeof filterDate !== 'undefined') {
        param.date_start = filterDate.date_start
        param.date_end = filterDate.date_end
      }

      appointmentOverview(param).then((response) => {
        this.dataWidget.total_appointment = response.data.data.total_appoinment
        this.dataWidget.ftotalrevenue = response.data.data.graph.ftotalrevenue
        this.dataWidget.totalrevenue = response.data.data.graph.totalrevenue
        this.dataWidget.pieData.rows = response.data.data.group
        this.dataWidget.chartData.rows = response.data.data.graph.datagraph
        this.dataWidget.date_start = moment(response.data.data.graph.date_start).format('DD MMM YYYY')
        this.dataWidget.date_end = moment(response.data.data.graph.date_end).format('DD MMM YYYY')
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    fetchMarketplaceOverview (filterDate) {
      // this.listLoading = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end,
        platform: 'M'
      }

      if (typeof filterDate !== 'undefined') {
        param.date_start = filterDate.date_start
        param.date_end = filterDate.date_end
      }
      appointmentOverview(param).then((response) => {
        this.dataMarketplace.total_appointment = response.data.data.total_appoinment
        this.dataMarketplace.ftotalrevenue = response.data.data.graph.ftotalrevenue
        this.dataMarketplace.totalrevenue = response.data.data.graph.totalrevenue
        this.dataMarketplace.pieData.rows = response.data.data.group
        this.dataMarketplace.chartData.rows = response.data.data.graph.datagraph
        this.dataMarketplace.date_start = moment(response.data.data.graph.date_start).format('DD MMM YYYY')
        this.dataMarketplace.date_end = moment(response.data.data.graph.date_end).format('DD MMM YYYY')
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    setDate (param) {
      if (param === 0) {
        if (this.date.length > 1) {
          this.menu1 = false
        }
      } else {
        if (this.date2.length > 1) {
          this.menu2 = false
        }
      }
    },

    getLogin () {
      this.listLoading = true
      var data = {
        username: 'rudiariyanto014@gmail.com',
        password: '123456'
      }
      this.$store.dispatch('LoginByUsername', data).then(() => {
        this.loading = false
        this.$router.push('/')
      }).catch((error) => {
        this.$message({
          type: 'error',
          title: error.statusCode,
          message: error.message
        })
        this.loading = false
      })
    },

    getLogout () {
      this.$store.dispatch('LogOut').then(() => {
        this.loading = false
        this.$router.push('/login')
      }).catch(error => {
        console.log('errorrrrrr', error)
        this.$message({
          type: 'error',
          title: error.statusCode,
          message: error.message
        })
        this.loading = false
      })
    },

    filterSubscription (data) {
      this.fetchSubcriptionOverview(data)
    },

    filterWidget (data) {
      this.fetchWidgetOverview(data)
    },

    filterMarketplace (data) {
      this.fetchMarketplaceOverview(data)
    },

    filterTreatment (data) {
      this.fetchTreatmentDemograph(data)
    },

    filterPlatform (data) {
      this.fetchPlatformDemograph(data)
    },

    setColor (ref) {
      this.styleMenu[ref].btnColor = 'transparent linear-gradient(180deg, #0BD0D4 0%, #00D79E 100%) 0% 0% no-repeat padding-box;'
      this.styleMenu[ref].txtColor = '#FFFFFF'
      this.styleMenu[ref].iconColor = '#FFFFFF'
    },

    reColor (ref) {
      this.styleMenu[ref].btnColor = '#FFFFFF'
      this.styleMenu[ref].txtColor = '#000000'
      this.styleMenu[ref].iconColor = '#0BD0D4'
    }
  }
}
</script>

<style lang="scss">
.mytable {
  .v-data-table-header {
    background-color: #F5F5F5;
  }
}

.zoom:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1)
}
</style>
