<template>
  <div>
    <div>
      <v-container class="grey lighten-5" style="max-width: 1280px !important">
        <v-row
          class="mb-6"
          no-gutters
        >
          <v-col :md="12">
            <v-card :shaped="true" :raised="true" :elevation="5" class="rounded-lg pa-5" style="height: 100%; max-height: 216px" >
              <div class="d-flex">
                <div style="width: 100%">
                  <span class="font-weight-medium" style="font-size: 22px;">{{ dataMerchant.name }}</span>
                  <div class="d-flex flex-row mb-5">
                    <div style="font-size: 14px">{{ lang.t('$vuetify.business_type') }} : {{ dataMerchant.business_type_name }}</div>
                  </div>
                  <div class="d-flex align-center" style="width: 100%">
                    <v-card :shaped="true" :raised="true" :elevation="5" class="d-flex align-center rounded-xl mb-3 text-center" style="width: 236px; height: 72px; background: transparent linear-gradient(180deg, #46E8BD 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;" >
                      <div style="width: 100%">
                        <div class="font-weight-medium" style="color: #FFFFFF; font-size: 24px">{{ dataMerchant.subscription_status }}</div>
                      </div>
                    </v-card>
                    <div class="px-3" style="width: 100%;">
                      <div class="d-flex align-center">
                        <span style="color: #767676; font-size: 14px; width: 100%">{{ dataMerchant.timeleft }}</span>
                        <div class="text-right" style="color: #767676; font-size: 18px; width: 100%;">1 Year (expired at {{ dataMerchant.fend_date_subscription}})</div>
                      </div>
                      <v-progress-linear :value="dataMerchant.pecentagetimeleftsubscription" height="16px" rounded color="#00D79E" background-color="#2882F5"></v-progress-linear>
                      <div class="d-flex align-center">
                        <span style="font-size: 14px; color: #F5295A; width: 100%">Time left: {{ dataMerchant.dayexpiredused }}</span>
                        <span class="text-right" style="font-size: 14px; color: #2882F5; width: 100%">{{ lang.t('$vuetify.your_package_by_extend') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-3" style="width: 100%; max-width: 200px">
                  <v-row>
                    <v-col>
                      <v-card :raised="true" :elevation="5" class="rounded-lg pa-2 mt-n2" @mouseover="setColor(1)" @mouseleave="reColor(1)" :style="'background: ' + styleMenu[1].btnColor" @click="setChangePackage">
                        <div class="d-flex flex-row align-center" >
                          <div class="pl-2 py-2 mr-2">
                              <v-icon x-large class="mt-2 mr-2" :color="styleMenu[1].iconColor">$vuetify.icons.icon_change_package</v-icon>
                          </div>
                          <span class="subtitle font-weight-bold" :style="'color: ' + styleMenu[1].txtColor">
                              {{ lang.t('$vuetify.change') }} {{ lang.t('$vuetify.package') }}
                          </span>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-card :raised="true" :elevation="5" class="rounded-lg pa-2 mt-n2" @mouseover="setColor(2)" @mouseleave="reColor(2)" :style="'background: ' + styleMenu[2].btnColor" @click="showExtendDialog">
                        <div class="d-flex flex-row align-center">
                          <div class="pl-2 py-2 mr-2">
                            <v-icon x-large class="mt-2 mr-2" :color="styleMenu[2].iconColor">$vuetify.icons.icon_extend_package</v-icon>
                          </div>
                          <span class="subtitle font-weight-bold" :style="'color: ' + styleMenu[2].txtColor">
                            Extend {{ lang.t('$vuetify.package') }}
                          </span>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          class="mb-6"
          no-gutters>
          <v-card class="rounded-xl" raised :elevation="5" style="width: 100%">
            <v-card-title>
              {{ lang.t('$vuetify.payment_history') }}
            </v-card-title>
            <v-skeleton-loader
              :loading="listLoading"
              :transition="transition"
              type="table">
                <v-data-table
                :headers="headers"
                :items="data"
                :search="search"
                :items-per-page="15"
                hide-default-footer
                class="elevation-1 mytable mb-4"
                style="border-bottom: none"
                @click:row="showBilling">
                  <template v-slot:header.name="{ header }">
                    <span style="color: #00B082">{{ lang.t('$vuetify.' + header.value) }}</span>
                  </template>
                  <template v-slot:item.payment_status_desc="{ item }">
                    <span v-if="item.payment_status === 0" class="text-uppercase" style="color: #F59700">{{ item.payment_status_desc }}</span>
                    <span v-if="item.payment_status === 1" class="text-uppercase" style="color: #2882F5">{{ item.payment_status_desc }}</span>
                  </template>
                  <template v-slot:item.transaction_status_desc="{ item }">
                    <v-chip v-if="item.transaction_status === 0" class="text-uppercase" color="#F59700" outlined style="background: #FEF2E0">{{ item.transaction_status_desc }}</v-chip>
                    <v-chip v-if="item.transaction_status === 1" class="text-uppercase" color="#2882F5" outlined style="background: #EBF4FF">{{ item.transaction_status_desc }}</v-chip>
                    <v-chip v-if="item.transaction_status === 3" class="text-uppercase" color="#F5295A" outlined style="background: #F5295A">{{ item.transaction_status_desc }}</v-chip>
                  </template>
                  <template v-slot:item.action="{ item }">
                  <v-btn icon>
                    <v-icon class="mr-2" @click="editItem(item)" color="#CDD2DA">mdi-dots-horizontal</v-icon>
                  </v-btn>
                  </template>
                </v-data-table>
            </v-skeleton-loader>
            <div class="align-center pb-4" style="width: 100%">
              <v-spacer></v-spacer><br>
              <!-- <v-pagination
              v-model="page"
              color="#00B082"
              :length="total_page"
              :total-visible="7"
              circle
              @input="changePage"
              ></v-pagination> -->
              <v-pagination
              v-model="page"
              color="#00B082"
              :length="total_page"
              :total-visible="7"
              circle
              ></v-pagination>
            </div>
          </v-card>
        </v-row>
        <v-dialog v-model="dialogChangePackage" max-width="50%">
          <v-card v-if="showPackage" class="pb-5">
            <v-toolbar color="elevation-0">
              <v-toolbar-title >
                <!-- <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.add') }}</span> -->
                <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.change') }} {{ lang.t('$vuetify.package') }}</span>
                <span class="caption ml-3" style="background: #EFEFEF; color: #767676 ">201920FD3D6</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div class="mx-6 mt-6">
              <div class="mt-3 ml-2">
                <div class="mb-3">
                  <v-icon x-small class="mt-n1 mr-2" color="#CDD2DA" >$vuetify.icons.business_logo</v-icon>
                  <span class="body-1">{{ dataMerchant.name }}</span>
                </div>
                <div class="mb-5">
                  <v-icon x-small class="mt-n1 mr-2" color="#CDD2DA" >$vuetify.icons.icon_product</v-icon>
                  <span class="body-1">{{ dataMerchant.subscription_status }} (Expired: {{ dataMerchant.fend_date_subscription }} ) <span style="color: #00D79E">{{ dataMerchant.timeleft }}</span></span>
                </div>
                <hr style="border: 1px dashed #CDD2DA">
              </div>
              <div class="ml-2">
                <div class="caption capitalize mb-n4 mt-6" style="color: #747474">{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.package_to_change') }}</div>
                <v-radio-group v-model="packageOption" :mandatory="false">
                  <div v-for="i in packageZenwel" :key="i.id" >
                    <div class="px-4 py-3 mt-4 rounded-lg" :style="'border: 1px solid #E0E0E0;' + i.background">
                      <div class="d-flex mt-2">
                        <v-radio :label="i.title" :value="i" :disabled="i.disable" style="width: 70%" @change="getPlan(i.id)"></v-radio>
                        <div v-if="i.id !== 1" class="text-right" style="width: 30%" >{{ i.fprice_monthly }} /month</div>
                        <div v-else class="text-right" style="width: 30%" >Free</div>
                      </div>
                      <div v-if="packageOption.id && packageOption.id === i.id && packageOption.id !== 1 && dataMerchant.id_subscription_status === 1">
                        <span class="caption">Select Plan</span>
                        <v-autocomplete v-model="tempPackage.duration" :items="listPlan" item-text="duration" item-value="id" outlined dense @change="setPrice"></v-autocomplete>
                      </div>
                    </div>
                    <div v-if="i.id === 1 && packageOption.id === 1" class="d-flex align-center px-4 mt-2" style="height: 40px; background: #FAF8E9">
                      <v-icon small class="mr-2" color="#F59700" >mdi-information-outline</v-icon>
                      <span style="font-size: 12px; color: #F59700">{{ lang.t('$vuetify.downgrade_note') }} {{ dataMerchant.fend_date_subscription }}.</span>
                    </div>
                  </div>
                  <!-- <v-radio label="Radio 2" value="radio-2"></v-radio> -->
                </v-radio-group>
              </div>
            </div>
            <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; width: 100%">
              <div class="ml-2 caption" style="width: 80%">
                <span>{{ lang.t('$vuetify.total') }} {{ lang.t('$vuetify.price') }}</span>
                <div v-if="dataMerchant.id_subscription_status !== 1" class="subtitle-1 font-weight-bold">
                  <div v-if="packageOption.id !== 1 && typeof packageOption.id !== 'undefined'">
                    {{ packageOption.fprice_monthly }}
                    <v-icon small class="mt-n1 mr-2" color="#00D79E" @click="showPriceDetail = true, showPackage = false" >mdi-information-outline</v-icon>
                  </div>
                  <div v-else>
                    FREE
                  <v-icon small class="mt-n1 mr-2" color="#00D79E" @click="showPriceDetail = true, showPackage = false" >mdi-information-outline</v-icon>
                  </div>
                </div>
                <div v-else>
                  <div v-if="packageOption.id !== 1 && typeof packageOption.id !== 'undefined'" class="subtitle-1 font-weight-bold">
                    {{ packageOption.fprice_plan }}
                    <v-icon small class="mt-n1 mr-2" color="#00D79E" >mdi-information-outline</v-icon>
                  </div>
                </div>
              </div>
              <div style="width: 20%">
                <v-btn color="#00D79E" class="float-right text-capitalize" style="color: #FFFFFF" @click="showPayment = true, showPackage = false">{{ lang.t('$vuetify.next') }}</v-btn>
              </div>
            </div>
          </v-card>
          <v-card v-if="showPriceDetail" class="pb-5">
            <v-toolbar color="elevation-0">
              <v-toolbar-title >
                <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.price_detail') }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon @click="showPriceDetail = false, showPayment = true">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div class="mx-6 mt-6">
              <div class="mt-3 ml-2">
                <div class="mb-3" style="width: 100%">
                  <v-row class="align-center" no-gutters>
                    <v-col :md="8">
                      <span class="body-1">Pro Package (2 months 24 days)</span>
                    </v-col>
                    <v-col :md="4" >
                      <div class="float-right">{{ dataPriceDetail.fprice_package }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="align-center mt-3" no-gutters>
                    <v-col :md="8">
                      <span class="body-1">Prorate Discount</span>
                    </v-col>
                    <v-col :md="4" >
                      <div class="float-right" style="color: #00D79E">{{ dataPriceDetail.fprorata_discount }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="align-center mt-3" no-gutters>
                    <v-col :md="8">
                      <span class="body-1">Admin Discount</span>
                    </v-col>
                    <v-col :md="4" >
                      <div class="float-right" style="color: #00D79E">{{ dataPriceDetail.fdiscount }}</div>
                    </v-col>
                  </v-row>
                </div>
                <hr style="border: 1px dashed #CDD2DA">
                <div class="mt-4" style="width: 100%">
                  <v-row class="align-center" no-gutters>
                    <v-col :md="8">
                      <span class="body-1 font-weight-bold">Total</span>
                    </v-col>
                    <v-col :md="4">
                      <div class="float-right font-weight-bold">{{ dataPriceDetail.ftotal_price }}</div>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="pa-4 mt-10 rounded-lg caption d-flex" style="background: #DDFBF3">
                <v-icon class="mr-2" color="#00D79E" >mdi-information-outline</v-icon>
                <span style="color: #00D79E">{{ dataPriceDetail.message }}</span>
              </div>
            </div>
          </v-card>
          <v-card v-if="showPayment" class="pb-5">
            <v-toolbar color="elevation-0">
              <v-toolbar-title >
                <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.payment_method') }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon @click="showPayment = false, showPackage = true">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div class="mx-6 mt-6">
              <div class="mt-3 ml-2">
                <div class="mb-3 elevation-5 pt-5 px-4" style="width: 100%; height: 72px">
                  <v-row class="align-center" no-gutters>
                    <v-col :md="8">
                      <span class="body-1 font-weight-bold">Total</span>
                    </v-col>
                    <v-col :md="4" >
                      <div v-if="dataMerchant.id_subscription_status !== 1" class="float-right font-weight-bold">
                        {{ packageOption.fprice_monthly }}
                      </div>
                      <div v-else class="float-right font-weight-bold">{{ this.packageOption.fprice_plan }}</div>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-7" style="width: 100%">
                  <span>{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.payment') }}</span>
                  <v-autocomplete v-model="tempPackage.payment_method" :items="listPayment" item-text="type" item-value="id" outlined dense ></v-autocomplete>
                </div>
                <div class="mt-7">
                  <span>Discount</span>
                  <v-text-field v-model="tempPackage.discount" outlined dense suffix="%" type="number"></v-text-field>
                </div>
              </div>
            </div>
            <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; width: 100%">
              <div class="ml-2 caption" style="width: 80%">
                <span>Total Price</span>
                <div v-if="packageOption.id !== 0 && typeof packageOption.id !== 'undefined'" class="subtitle-1 font-weight-bold">
                  {{ packageOption.fprice_plan }}
                  <v-icon small class="mt-n1 mr-2" color="#00D79E" @click="handlePriceDetail" >mdi-information-outline</v-icon>
                </div>
              </div>
              <div style="width: 20%">
                <v-btn color="#00D79E" class="float-right text-capitalize" style="color: #FFFFFF" @click="setPackage">Apply</v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogExtendPackage" max-width="50%">
          <v-card v-if="showExtendPackage" class="pb-5">
            <v-toolbar color="elevation-0">
              <v-toolbar-title >
                <!-- <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.add') }}</span> -->
                <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.change') }} {{ lang.t('$vuetify.package') }}</span>
                <span class="caption ml-3" style="background: #EFEFEF; color: #767676 ">201920FD3D6</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div class="mx-6 mt-6">
              <div class="mt-3 ml-2">
                <div class="mb-3">
                  <v-icon x-small class="mt-n1 mr-2" color="#CDD2DA" >$vuetify.icons.business_logo</v-icon>
                  <span class="body-1">{{ dataMerchant.name }}</span>
                </div>
                <div class="mb-5">
                  <v-icon x-small class="mt-n1 mr-2" color="#CDD2DA" >$vuetify.icons.icon_product</v-icon>
                  <span class="body-1">{{ dataMerchant.subscription_status }} (Exp: {{ dataMerchant.fend_date_subscription }} ) <span style="color: #00D79E">{{ dataMerchant.timeleft }}</span></span>
                </div>
                <hr style="border: 1px dashed #CDD2DA">
              </div>
              <div class="ml-2">
                <div>
                  <span class="caption">Select Plan</span>
                  <v-autocomplete v-model="extendPlan.id" :items="listPlan" item-text="duration" item-value="id" outlined dense >
                    <template v-slot:item="data" @click="selectPlan">
                      <div style="width: 100%" @click="selectPlan(data.item)">{{ data.item.duration }} {{ data.item.price }}</div>
                    </template>
                  </v-autocomplete>
                </div>
              </div>
              <div class="pa-2 mb-3 mt-n1 rounded-lg caption d-flex" style="background: #DDFBF3">
                <v-icon class="mr-2" color="#00D79E" >mdi-information-outline</v-icon>
                <span style="color: #00D79E">The extention will be active from 12 Jul 2020 to 12 Jul 2021</span>
              </div>
            </div>
            <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; width: 100%">
              <div class="ml-2 caption" style="width: 80%">
                <span>{{ lang.t('$vuetify.total') }} {{ lang.t('$vuetify.price') }}</span>
                <div v-if="typeof extendPlan.id !== 'undefined'" class="subtitle-1 font-weight-bold">
                  {{ extendPlan.fprice }}
                </div>
              </div>
              <div style="width: 20%">
                <v-btn color="#00D79E" class="float-right text-capitalize" style="color: #FFFFFF" @click="showExtendPayment = true, showExtendPackage = false">Next</v-btn>
              </div>
            </div>
          </v-card>
          <v-card v-if="showExtendPayment" class="pb-5">
            <v-toolbar color="elevation-0">
              <v-toolbar-title >
                <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.payment_method') }}</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon @click="showPayment = false, showPackage = true">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div class="mx-6 mt-6">
              <div class="mt-3 ml-2">
                <div class="mb-3 elevation-5 pt-5 px-4" style="width: 100%; height: 72px">
                  <v-row class="align-center" no-gutters>
                    <v-col :md="8">
                      <span class="body-1 font-weight-bold">Total</span>
                    </v-col>
                    <v-col :md="4" >
                      <div class="float-right font-weight-bold">{{ extendPlan.fprice }}</div>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-7" style="width: 100%">
                  <span>{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.payment') }}</span>
                  <v-autocomplete v-model="tempExtend.payment_method" :items="listPayment" item-text="type" item-value="id" outlined dense ></v-autocomplete>
                </div>
                <div class="mt-7">
                  <span>Discount</span>
                  <v-text-field v-model="tempExtend.discount" outlined dense suffix="%" type="number"></v-text-field>
                </div>
              </div>
            </div>
            <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; width: 100%">
              <div class="ml-2 caption" style="width: 80%">
                <span>Total Price</span>
                <div class="subtitle-1 font-weight-bold">
                  {{ extendPlan.fprice }}
                  <v-icon small class="mt-n1 mr-2" color="#00D79E" @click="showPriceDetail = true, showPackage = false" >mdi-information-outline</v-icon>
                </div>
              </div>
              <div style="width: 20%">
                <v-btn color="#00D79E" class="float-right text-capitalize" style="color: #FFFFFF" @click="setExtend">Apply</v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <!-- Billing Detail -->
        <billing-detail
          v-if="dialogBillingDetail"
          :show-dialog="dialogBillingDetail"
          :billing-data="billingData"
          :list-payment="listPayment"
          :package-option="packageOption"
          :temp-package="tempPackage"
          @close="dialogBillingDetail = false"
          @update="handleUpdate"></billing-detail>
      </v-container>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { detailBussiness } from '@/api/zenwelbiz'
import { fetchSubscriptionMerchant,
  fetchPackageZenwel,
  fetchPricePlan,
  fetchPayments,
  updateSubscription,
  getPriceDetail,
  fetchSubscriptionInvoice } from '@/api/zenwel-biz/merchant'
import billingDetail from './_billing-detail'

export default {
  name: 'Subscription',
  components: {
    billingDetail
  },
  data () {
    return {
      headers: [
        {
          text: 'Billing Number',
          align: 'left',
          value: 'no'
        },
        {
          text: 'Date',
          align: 'center',
          value: 'fcreated_time',
          width: 150
        },
        {
          text: 'Business Name',
          align: 'left',
          value: 'com_name'
        },
        {
          text: 'Activity',
          align: 'left',
          value: 'type'
        },
        {
          text: 'Price',
          align: 'left',
          value: 'price'
        },
        {
          text: 'Created By',
          align: 'left',
          value: 'created_by'
        },
        {
          text: 'Paym. Status',
          align: 'left',
          value: 'payment_status_desc'
        },
        {
          text: 'Billing Status',
          align: 'left',
          value: 'transaction_status_desc'
        },
        {
          text: 'Action',
          align: 'center',
          value: 'action',
          width: 150
        }
      ],
      date_start: moment().subtract(6, 'd').format('YYYY-MM-DD'),
      date_end: moment().format('YYYY-MM-DD'),
      styleMenu: [
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        },
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        },
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        },
        {
          btnColor: '#FFFFFF',
          txtColor: '#000000',
          iconColor: '#0BD0D4'
        }
      ],
      data: [],
      search: '',
      dialogChangePackage: false,
      showPriceDetail: false,
      showPackage: true,
      packageOption: {
        price_plan: {
          fprice: '0'
        }
      },
      packageZenwel: [],
      transition: 'scale-transition',
      paymentMethod: '',
      listPayment: [],
      packagePlan: '',
      listPlan: [],
      dialogExtendPackage: false,
      showExtendPackage: true,
      dialogBillingDetail: false,
      extendPlan: {},
      showPayment: false,
      showExtendPayment: false,
      listLoading: false,
      total_page: 0,
      page: 0,
      total_activity: 0,
      dataMerchant: {},
      tempPackage: {
        com_id: this.$route.params.id,
        subscription_id: '',
        duration: '',
        payment_method: '',
        discount: 0
      },
      tempExtend: {
        com_id: this.$route.params.id,
        subscription_id: '',
        duration: '',
        payment_method: '',
        discount: 0
      },
      dataPriceDetail: {},
      showPendingPayment: false,
      showBillingDetail: true,
      showChangePayment: false,
      paidStatus: 2,
      billingData: {}
    }
  },

  mounted () {
    this.getInvoice()
    this.getPackage()
    this.getCurrentPackage()
    this.getPayment()
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  methods: {
    getInvoice (page) {
      this.listLoading = true
      var params = {
        com_id: this.$route.params.id,
        // page: page,
        // per_page: 10,
        sort_type: 'desc',
        sort_column: 'id'
      }
      fetchSubscriptionMerchant(params).then((response) => {
        this.data = response.data.data
        this.total_page = response.data.meta.last_page
        this.page = response.data.meta.current_page
        this.total_activity = response.data.total
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getPackage (page) {
      this.listLoading = true
      var params = {
        currency: 'IDR'
      }
      fetchPackageZenwel(params).then((response) => {
        this.packageZenwel = response.data.data
        this.packageZenwel.map(i => {
          i.disable = false
          i.background = ''
        })
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getCurrentPackage (page) {
      this.listLoading = true
      // var params = {
      //   com_id: this.$route.params.id
      // }
      detailBussiness(this.$route.params.id).then((response) => {
        this.dataMerchant = response.data.data
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    setColor (ref) {
      this.styleMenu[ref].btnColor = 'transparent linear-gradient(180deg, #0BD0D4 0%, #00D79E 100%) 0% 0% no-repeat padding-box;'
      this.styleMenu[ref].txtColor = '#FFFFFF'
      this.styleMenu[ref].iconColor = '#FFFFFF'
    },

    reColor (ref) {
      this.styleMenu[ref].btnColor = '#FFFFFF'
      this.styleMenu[ref].txtColor = '#000000'
      this.styleMenu[ref].iconColor = '#0BD0D4'
    },

    selectPlan (data) {
      this.extendPlan = data
      this.tempExtend.duration = data.id
    },

    setChangePackage () {
      this.packageZenwel.map(i => {
        if (i.id === this.dataMerchant.id_subscription_status) {
          i.disable = true
          i.background = 'background: #F5F5F5; color: #BEC2C4'
        }
      })
      this.dialogChangePackage = true
    },

    getPlan (data) {
      this.listLoading = true
      this.listPlan = []
      var params = {
        subscription_id: data
      }
      fetchPricePlan(params).then((response) => {
        this.listPlan = response.data.data
        console.log('package', this.packageOption)
        this.packageOption.fprice_plan = '0'
        this.packageOption.price_plan = '0'
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getPayment () {
      this.listLoading = true
      fetchPayments().then((response) => {
        this.listPayment = response.data.data
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    setPackage () {
      this.tempPackage.subscription_id = this.packageOption.id
      this.tempPackage.discount = parseInt(this.tempPackage.discount)
      console.log('paket', this.tempPackage)
      this.listLoading = true
      updateSubscription(this.tempPackage).then((response) => {
        console.log(response)
        this.dialogChangePackage = false
        this.loadMount()
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    setExtend () {
      this.tempExtend.subscription_id = this.dataMerchant.id_subscription_status
      this.tempExtend.discount = parseInt(this.tempExtend.discount)
      console.log('paket', this.tempExtend)
      this.listLoading = true
      updateSubscription(this.tempExtend).then((response) => {
        console.log(response)
        this.loadMount()
        this.dialogExtendPackage = false
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    showExtendDialog () {
      this.listLoading = true
      this.listPlan = []
      var params = {
        subscription_id: this.dataMerchant.id_subscription_status
      }
      fetchPricePlan(params).then((response) => {
        this.listPlan = response.data.data
        this.dialogExtendPackage = true
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    setPrice (data) {
      this.listPlan.map(i => {
        if (i.id === data) {
          this.packageOption.fprice_plan = i.fprice
          this.packageOption.price_plan = i.price
        }
      })
    },

    handlePriceDetail () {
      var params = {
        com_id: this.$route.params.id,
        subscription_id: this.packageOption.id,
        discount: this.tempPackage.discount
      }
      getPriceDetail(params).then((response) => {
        this.dataPriceDetail = response.data
        this.showPriceDetail = true
        this.showPackage = false
        this.showPayment = false
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    showBilling (data) {
      fetchSubscriptionInvoice(data.id).then((response) => {
        this.billingData = response.data.data
        if (this.billingData.cancelable === 0) {
          this.billingData.disable = true
        } else {
          this.billingData.disable = false
        }
        console.log('respon', response)
        this.dialogBillingDetail = true
      }).catch((error) => {
        console.log('rsp', error)
      })
    },

    loadMount () {
      this.getInvoice()
      this.getPackage()
      this.getCurrentPackage()
      this.getPayment()
    },

    handleUpdate () {
      this.dialogBillingDetail = false
      this.loadMount()
    }
  }
}
</script>

<style lang="scss">
.mytable {
  .v-data-table-header {
    background-color: #F5F5F5;
  }
}
</style>
