<template>
    <v-dialog v-model="showDialog" max-width="50%">
        <!-- Billing detail -->
        <v-card v-if="showBillingDetail" class="pb-5">
            <v-toolbar color="elevation-0">
                <v-toolbar-title >
                <span class="ml-4 font-weight-bold">Billing Detail</span>
                <span class="caption ml-3" style="background: #EFEFEF; color: #767676 ">{{ billingData.no }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn icon @click="handleClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="mx-6 mt-6">
                <div class="mt-3 ml-2">
                    <div class="mb-3">
                        <span class="subtitle-1">{{ billingData.com_name }}</span>
                        <div v-if="billingData.type === 'extend'" class="body-2">Upgrade Package: {{ billingData.current_subcription_status }} to {{ billingData.subscription_name }} ( {{ billingData.num_of_period_desc }} )</div>
                        <div v-if="billingData.type === 'downgrade'" class="body-2">Downgrade Package: {{ billingData.current_subcription_status }} to {{ billingData.subscription_name }} ( {{ billingData.num_of_period_desc }} )</div>
                    </div>
                    <div class="mb-3">
                        <span class="subtitle-1" style="color: #4CD137">{{ billingData.fprice }}</span>
                        <span class="caption" style="color: #00D79E">{{ lang.t('$vuetify.see_price_detail') }}</span>
                    </div>
                    <div class="mb-3">
                        <div v-if="billingData.transaction_status === 1">
                            <v-chip class="my-2" label color="#2882F5" small style="color: #FFFFFF">{{ billingData.transaction_status_desc }}</v-chip>
                        </div>
                        <div v-if="billingData.transaction_status === 0">
                            <v-chip class="ma-2 text-capitalize" label color="#FFF7EA" small style="border: 1px solid #F59700; color: #F59700">{{ billingData.transaction_status_desc }}</v-chip>
                        </div>
                    </div>
                    <hr style="border: 1px dashed #CDD2DA">
                </div>
                <div class="ml-2 mt-3">
                    <span>Detail</span>
                    <div class="mt-4 d-flex">
                        <v-icon small color="#CDD2DA" style="font-size: 20px" >mdi-information-outline</v-icon>
                        <div class="body-2 ml-2 text-capitalize" >
                            <span v-if="billingData.transaction_status === 1" style="color: #2882F5">{{ billingData.payment_status_desc }} <span style="color: #000000">{{ billingData.fpay_date }}</span></span>
                            <span v-if="billingData.transaction_status === 0" style="color: #F5295A">{{ billingData.payment_status_desc }} </span>
                        </div>
                    </div>
                    <div class="mt-4">
                        <v-icon style="font-size: 20px">mdi-calendar-range</v-icon>
                        <span class="body-2 ml-2">{{ billingData.fcreated }}</span>
                    </div>
                    <div class="d-flex align-center mt-4">
                        <v-avatar size="20">
                        <v-img :src="billingData.created_by_photo"></v-img>
                        </v-avatar>
                        <span class="body-2 ml-2">{{ billingData.created_by_name }} ({{ billingData.created_by }})</span>
                    </div>
                    <div class="mt-4">
                        <v-icon style="font-size: 20px">mdi-cash-multiple</v-icon>
                        <span class="caption ml-2">Bank Transfer (BCA Virtual Account - Virtual Number: 1182085291829382)</span>
                    </div>
                </div>
                <div class="d-flex pt-4 px-4">
                    <div v-if="billingData.transaction_status === 1" style="width: 100%">
                        <div class="mr-2">
                        <v-btn v-if="billingData.cancelable === 1" color="#FFFFFF" class="float-right text-capitalize" style="border: 1px solid #F5295A; color: #F5295A" @click="handleCancel" >Cancel Transaction</v-btn>
                        </div>
                        <div class="mr-2">
                        <v-btn class="float-right text-capitalize mr-2" @click="showPendingPayment = true, showBillingDetail = false">Download PDF</v-btn>
                        </div>
                    </div>
                    <div v-if="billingData.transaction_status === 0" style="width: 100%">
                        <div class="ml-2">
                            <v-btn color="#00D79E" class="float-right text-capitalize" style="color: #FFFFFF;" @click="showPendingPayment = true, showBillingDetail = false">Confirm Payment</v-btn>
                        </div>
                        <div v-if="billingData.payment_status === 0" class="mr-2">
                            <v-btn color="#FFFFFF" class="float-right text-capitalize mr-2" style="color: #F5295A; border: 1px solid #F5295A;" @click="handleCancel" >Cancel Transaction</v-btn>
                        </div>
                        <div class="mr-2">
                            <v-btn class="text-capitalize mr-2" >Download PDF</v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>

        <!-- Confirm Payment -->
        <v-card v-if="showPendingPayment" class="pb-5">
        <v-toolbar color="elevation-0">
            <v-toolbar-title>
                <div class="d-flex align-center">
                    <v-btn icon @click="showPendingPayment = false, showBillingDetail = true">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <div class="font-weight-bold">{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.payment_method') }}</div>
                </div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn icon @click="showPayment = false, showPackage = true">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <div class="mx-6 mt-6">
            <div class="mt-3 ml-2">
            <div class="mb-3 elevation-5 pt-5 px-4" style="width: 100%; height: 112px">
                <div class="d-flex align-center" no-gutters>
                <div style="width: 100%">
                    <span class="body-1 font-weight-bold">Total</span>
                </div>
                <div style="width: 100%">
                    <div class="float-right font-weight-bold">{{ billingData.fprice }}</div>
                </div>
                </div>
                <div class="d-flex align-center mt-4" no-gutters>
                <div style="width: 100%">
                    <span class="body-1 font-weight-bold">{{ lang.t('$vuetify.payment_method') }}</span>
                </div>
                <div style="width: 100%">
                    <div class="float-right font-weight-bold">{{ billingData.method_payment }}</div>
                </div>
                </div>
            </div>
            <div class="mt-7">
                <span class="caption" style="color: #747474">{{ lang.t('$vuetify.input') }} {{ lang.t('$vuetify.reference_code') }}</span>
                <v-text-field v-model="tempPackage.ref_code" outlined dense></v-text-field>
            </div>
            </div>
            <div class="d-flex pt-4 px-4">
            <div style="width: 100%">
                <div class="ml-2">
                <v-btn color="#00D79E" class="float-right text-capitalize" style="color: #FFFFFF;" @click="setPayment(false)">{{ lang.t('$vuetify.apply') }}</v-btn>
                </div>
                <div class="mr-2">
                <v-btn color="#FFFFFF" class="float-right text-capitalize mr-2" style="border: 1px solid #E0E0E0;" @click="showChangePayment = true, showPendingPayment = false" >{{ lang.t('$vuetify.change') }} {{ lang.t('$vuetify.payment_method') }}</v-btn>
                </div>
            </div>
            </div>
        </div>
        </v-card>

        <!-- Change payment method -->
        <v-card v-if="showChangePayment" class="pb-5">
            <v-toolbar color="elevation-0">
                <v-toolbar-title >
                    <div class="d-flex align-center">
                        <v-btn icon @click="showChangePayment = false, showPendingPayment = true">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        <div class="ml-4 font-weight-bold">{{ lang.t('$vuetify.change') }} {{ lang.t('$vuetify.payment_method') }}</div>
                    </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-btn icon @click="showPayment = false, showPackage = true">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <div class="mx-6 mt-6">
                <div class="mt-3 ml-2">
                <div class="mb-3 elevation-5 pt-5 px-4" style="width: 100%; height: 72px">
                    <v-row class="align-center" no-gutters>
                    <v-col :md="8">
                        <span class="body-1 font-weight-bold">Total</span>
                    </v-col>
                    <v-col :md="4" >
                        <div class="float-right font-weight-bold">Rp. 290.000</div>
                    </v-col>
                    </v-row>
                </div>
                <div class="mt-7" style="width: 100%">
                    <span>{{ lang.t('$vuetify.select') }} {{ lang.t('$vuetify.payment') }}</span>
                    <v-autocomplete v-model="tempPackage.payment_method" :items="listPayment" item-text="type" item-value="id" outlined dense ></v-autocomplete>
                </div>
                <div class="mt-7">
                    <span>Discount</span>
                    <v-text-field v-model="tempPackage.discount" outlined dense suffix="%" type="number"></v-text-field>
                </div>
                </div>
            </div>
            <div class="d-flex pt-4 px-4" style="box-shadow: 0px -5px 5px #F5F5F5; width: 100%">
                <div class="ml-2 caption" style="width: 80%">
                <span>Total Price</span>
                <div v-if="packageOption.id !== 0 && typeof packageOption.id !== 'undefined'" class="subtitle-1 font-weight-bold">
                    {{ packageOption.fprice_plan }}
                    <v-icon small class="mt-n1 mr-2" color="#00D79E" @click="handlePriceDetail" >mdi-information-outline</v-icon>
                </div>
                </div>
                <div style="width: 20%">
                <v-btn color="#00D79E" class="float-right text-capitalize" style="color: #FFFFFF" @click="setPayment(true)">{{ lang.t('$vuetify.apply') }}</v-btn>
                </div>
            </div>
        </v-card>

        <!-- Cancel Transaction -->
        <cancel-package v-if="showCancel"
            :billing-data="billingData"
            @close="handleClose"
            @update="handleClose(true)"
            @back="handleBack"></cancel-package>
    </v-dialog>
</template>

<script>
import { updatePayment } from '@/api/zenwel-biz/merchant'
import cancelPackage from './_cancel-package'
export default {
  name: 'BillingDetail',
  props: ['billingData', 'showDialog', 'tempPackage', 'listPayment', 'packageOption'],

  components: {
    cancelPackage
  },

  data () {
    return {
      showChangePayment: false,
      showPendingPayment: false,
      showBillingDetail: true,
      dialogBilling: true,
      showCancel: false
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  methods: {
    handleClose (update) {
      this.showChangePayment = false
      this.showPendingPayment = false
      this.showBillingDetail = false
      this.showCancel = false
      if (!update) {
        this.$emit('close')
      } else {
        this.$emit('update')
      }
    },

    handleCancel () {
      this.showBillingDetail = false
      this.showChangePayment = false
      this.showPendingPayment = false
      this.showCancel = true
    },

    handleBack () {
      this.showCancel = false
      this.showBillingDetail = true
    },

    setPayment (payment) {
      let data = {}
      if (payment) {
        data = {
          id: this.billingData.id,
          payment_method: this.tempPackage.payment_method,
          discount: this.tempPackage.discount
        }
      } else {
        data = {
          id: this.billingData.id,
          ref_code: this.tempPackage.ref_code
        }
      }
      updatePayment(data).then((response) => {
        console.log(response)
        this.handleClose(true)
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
    }
  }
}
</script>
